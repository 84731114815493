/*
 *'use strict';
 */

import 'core-js/es/object';
import 'core-js/es/typed-array';
import 'core-js/es/object';
import 'core-js/es/set';
import Cookies from 'universal-cookie';
import { Auth } from 'aws-amplify';
import React from 'react';

import axios from 'axios';

class AccountSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            accountList: [],
        };
    }

    componentDidMount() {
        //fetch and update users
        this.fetchAccountList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        $(function () {
            $('[data-toggle="tooltip"]').tooltip({
                content: function () {
                    return $(this).prop('title');
                },
            });
        });
    }

    fetchAccountList = () => {
        const axiosConfig = {
            headers: {
                Accept: 'application/json',
            },
            withCredentials: true,
        };
        axios
            .get('/login/getAvailableAccounts', axiosConfig)
            .then(async (response) => {
                if (response.data.status === 'SUCCESS') {
                    this.setState({ accountList: response.data.data });
                } else {
                    window.location.href = '/login';
                }
            })
            .catch(function (error) {
                throw Error(error.message);
            });
    };

    //On click of a user -> make a post to backend to signin to this user_id
    handleSelectUser = async (userId) => {
        let data = new FormData();
        data.append('user_selected', userId);

        let csrf = document.querySelector('meta[name="csrf-hash"]').content;
        data.append('csrfToken', csrf);

        const axiosConfig = {
            headers: {
                Accept: 'application/json',
            },
            withCredentials: true,
        };
        axios
            .post('/login/select_account', data, axiosConfig)
            .then(async (response) => {
                if (response.data.status === 'SUCCESS') {
                    const cookies = new Cookies();
                    cookies.remove('crossAppSigninNoRedirect', Auth._storage);
                    data = response.data.data;
                    window.location.href = response.data.data.redirectUri;
                    this.setState({
                        showLoader: false,
                    });
                } else {
                    this.setState({
                        showSigningInScreen: false,
                        errorMessage: response.data.data.message,
                    });
                    //redirecting user back to login page
                    window.location.href = '/login';
                }
            })
            .catch(function (error) {
                throw Error(error.message);
            });
    };

    render() {
        return (
            <React.Fragment>
                <div className="container" role="main">
                    <div className="row">
                        <div className="col-md-12 loginForm">
                            <section>
                                <div className="container user-list-container" role="main">
                                    <div className="row">
                                        <div className="col-md-12 heading-sec user-list-title">
                                            <h4>
                                                <i className="fa fa-users" aria-hidden="true"></i>&nbsp; Select An
                                                Account
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="row user-list-content">
                                        <form
                                            className="form-horizontal"
                                            autoComplete="off"
                                            method="post"
                                            id="account-selector-form"
                                        >
                                            <input type="hidden" value="{{ csrf_key() }}" name="csrf-name" />
                                            <input type="hidden" value="{{ csrf_token() }}" name="csrfToken" />
                                            {this.renderAccountRows()}
                                        </form>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    renderAccountRows = () => {
        let panels = [];
        let accountList = this.state.accountList;
        for (let i = 0; i < accountList.length; i++) {
            panels.push(
                <div
                    key={`account_select_panel_${accountList[i].user_id}`}
                    className="panel panel-info user-list-panel"
                >
                    <div
                        className={`panel-heading user-list-heading ${
                            accountList[i].primary_user ? 'user-list-primary-user' : 'user-list-sub-user'
                        }`}
                    >
                        <div>
                            <span
                                className="glyphicon glyphicon-info-sign user-list-icon"
                                aria-hidden="true"
                                data-toggle="tooltip"
                                title={this.renderTooltipContent(accountList[i])}
                            ></span>
                        </div>
                        <div className="user-list-body" onClick={(e) => this.handleSelectUser(accountList[i].user_id)}>
                            <div>{accountList[i].organization}</div>
                            <div>{accountList[i].role_name}</div>
                        </div>
                        <div onClick={(e) => this.handleSelectUser(accountList[i].user_id)}>
                            <span
                                className="glyphicon glyphicon-chevron-right user-list-icon"
                                aria-hidden="true"
                            ></span>
                        </div>
                    </div>
                </div>
            );
        }
        return panels;
    };

    renderTooltipContent = (account) => {
        let tooltip = 'Name: ' + account.first_name + ' ' + account.last_name + '<br />';
        tooltip += 'Email: ' + account.email + '<br />';
        tooltip += 'Role: ' + account.role_name + '<br />';
        tooltip += 'Organization: ' + account.organization + '<br />';
        tooltip += 'Services: <ul>';

        for (const i in account.services) {
            tooltip += '<li key="' + account.user_id + '_service_' + i + '">' + account.services[i] + '</li>';
        }

        tooltip += '</ul>';

        return tooltip;
    };
}

export default AccountSelector;
