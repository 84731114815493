/*
 *'use strict';
 */

import 'core-js/es/object';
import 'core-js/es/typed-array';
import 'core-js/es/object';
import 'core-js/es/set';
import { Amplify, Auth } from 'aws-amplify';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import getAmplifyConfiguration from '../../configurations/components/cognito/configuration';

import UserAccountAction from './UserAccountAction';

let auth = getAmplifyConfiguration();

Amplify.configure({
    Auth: auth,
});

const axiosConfig = {
    headers: {
        Accept: 'application/json',
    },
    withCredentials: true,
};

const UserAccountActionsindex = (props) => {
    const [emailSending, setEmailSending] = useState({
        isDisabled: false,
        message: '',
    });
    const [usernameSending, setUsernameSending] = useState({
        isDisabled: false,
        message: '',
    });
    const [accountIsAlreadyActive, setAccountIsAlreadyActive] = useState(false);

    const [emailFieldValue, setEmailFieldValue] = useState('');
    const [dbEmailFieldValue, setDbEmailFieldValue] = useState('');

    const [usernameFieldValue, setUsernameFieldValue] = useState('');
    const [dbUsernameFieldValue, setDbUsernameFieldValue] = useState('');

    const [verificationCodeStatus, setVerificationCodeStatus] = useState({});
    const [resetPasswordStatus, setResetPasswordStatus] = useState({});
    const [activationEmailStatus, setActivationEmailStatus] = useState({});

    const theEmailFieldValue = document.getElementById('emailAddress');
    const theUsernameFieldValue = document.getElementById('username(mustBeAnEmail)');

    const sendCode = async (emailType) => {
        try {
            const result = await axios.post(
                `/users/${props.user.user_id}/edit/manualAccountActionEmails`,
                { emailType: emailType },
                axiosConfig
            );

            return result.data;
        } catch (error) {
            throw new Error(error.message);
        }
    };

    const sendVerificationCode = async () => {
        try {
            setVerificationCodeStatus({
                status: 'pending',
                message: 'Sending a verification code email...',
            });
            const result = await sendCode('verification_code');
            setVerificationCodeStatus({
                status: 'success',
                message: result.message,
            });
        } catch (error) {
            setVerificationCodeStatus({
                status: 'error',
                message: 'There was an issue sending the code. Please try again or contact your state administrator.',
            });
        }
    };

    const sendActivationEmail = async () => {
        try {
            setActivationEmailStatus({
                status: 'pending',
                message: 'Sending an account activation email...',
            });
            const result = await sendCode('account_activation');
            setActivationEmailStatus({
                status: 'success',
                message: result.message,
            });
        } catch (error) {
            setActivationEmailStatus({
                status: 'error',
                message:
                    'There was an issue sending the account activation email. Please try again or contact your state administrator.',
            });
        }
    };

    const sendResetPasswordEmail = async () => {
        try {
            setResetPasswordStatus({
                status: 'pending',
                message: 'Sending a password reset email...',
            });
            const result = await sendCode('password_reset');
            setResetPasswordStatus({
                status: 'success',
                message: result.message,
            });
        } catch (error) {
            setResetPasswordStatus({
                status: 'error',
                message:
                    'There was an issue sending the reset password email. Please try again or contact your state administrator.',
            });
        }
    };

    const fieldEventListener = (element, stateSetter) => {
        element.addEventListener('change', async (e) => {
            stateSetter(e.target.value);
        });
    };

    const handleFieldValue = (
        fieldValue,
        setEmailsCanBeSent,
        setEmailsCanBeSentTrueObj,
        setEmailsCanBeSentFalseObj,
        setFieldValue,
        setOnLoadFieldValue
    ) => {
        if (!fieldValue || fieldValue === '') {
            setEmailsCanBeSent(setEmailsCanBeSentFalseObj);
        } else {
            setEmailsCanBeSent(setEmailsCanBeSentTrueObj);
        }

        setFieldValue(fieldValue);
        setOnLoadFieldValue(fieldValue);
    };

    const currentEmailFieldValue = () => {
        if (!theEmailFieldValue.value || theEmailFieldValue.value === '') {
            setEmailSending({
                isDisabled: true,
                message: `User actions are disabled until a valid email address is saved`,
            });
        } else {
            setEmailSending({ isDisabled: true, message: '' });
        }

        setEmailFieldValue(theEmailFieldValue.value);
        setDbEmailFieldValue(theEmailFieldValue.value);
    };

    const currentUsernameFieldValue = () => {
        if (!theUsernameFieldValue.value || theUsernameFieldValue.value === '') {
            setEmailSending({
                isDisabled: true,
                message: `User actions are disabled until a valid username is saved`,
            });
        } else {
            setEmailSending({ isDisabled: true, message: '' });
        }

        setUsernameFieldValue(theUsernameFieldValue.value);
        setDbUsernameFieldValue(theUsernameFieldValue.value);
    };

    const emailFieldEventListener = () => {
        fieldEventListener(theEmailFieldValue, setEmailFieldValue);
    };

    const userNameFieldEventListener = () => {
        fieldEventListener(theUsernameFieldValue, setUsernameFieldValue);
    };

    useEffect(() => {
        emailFieldEventListener();

        if (!theEmailFieldValue) return;

        if (theEmailFieldValue.value !== emailFieldValue || theEmailFieldValue.value !== dbEmailFieldValue) {
            if (dbEmailFieldValue.length > 0) {
                setEmailSending({
                    isDisabled: true,
                    message: `Some user actions are disabled until the updated email address is saved or reset to ${dbEmailFieldValue}`,
                });
            } else {
                setEmailSending({
                    isDisabled: true,
                    message: `User actions are disabled until a valid email address is saved`,
                });
            }
        } else {
            setEmailSending({ isDisabled: false, message: '' });
        }

        return () => {
            theEmailFieldValue.removeEventListener('change', () => {});
        };
    }, [emailFieldValue]);

    useEffect(() => {
        userNameFieldEventListener();

        if (!theUsernameFieldValue) return;

        if (
            theUsernameFieldValue.value !== usernameFieldValue ||
            theUsernameFieldValue.value !== dbUsernameFieldValue
        ) {
            if (dbUsernameFieldValue.length > 0) {
                setUsernameSending({
                    isDisabled: true,
                    message: `Some user actions are disabled until the updated username (must be a valid email) is saved or reset to ${dbUsernameFieldValue}`,
                });
            } else {
                setUsernameSending({
                    isDisabled: true,
                    message: `User actions are disabled until a valid username is saved`,
                });
            }
        } else {
            setUsernameSending({ isDisabled: false, message: '' });
        }

        return () => {
            theUsernameFieldValue.removeEventListener('change', () => {});
        };
    }, [usernameFieldValue]);

    useEffect(() => {
        let accountIsAlreadyActivated = props.user.status === '1' && props.user.admin_activated === '1';
        setAccountIsAlreadyActive(accountIsAlreadyActivated ? true : false);

        currentEmailFieldValue();
        emailFieldEventListener();

        currentUsernameFieldValue();
        userNameFieldEventListener();

        return () => {
            theEmailFieldValue.removeEventListener('change', () => {});
            theUsernameFieldValue.removeEventListener('change', () => {});
        };
    }, []);

    return (
        <>
            <fieldset className="d-flex flex-column">
                <legend className="h5 fw-bold">User Account Actions</legend>
                {emailSending?.isDisabled && <div className="text-danger">{emailSending?.message}</div>}
                {usernameSending?.isDisabled && <div className="text-danger">{usernameSending?.message}</div>}
                <UserAccountAction
                    title="Verification Code"
                    subTitle="Send verification code to the user's username"
                    buttonLabel="Send Verification Code"
                    handleClick={sendVerificationCode}
                    isDisabled={usernameSending.isDisabled}
                    uniqueID="btnUserAccountActionVerificationCode"
                    statusMessage={verificationCodeStatus}
                />
                <UserAccountAction
                    title="Account Activation"
                    subTitle="Send reset password instructions to the user's email"
                    buttonLabel="Send Activation Email"
                    handleClick={sendActivationEmail}
                    isDisabled={accountIsAlreadyActive || emailSending.isDisabled}
                    uniqueID="btnUserAccountActionAccountActivation"
                    statusMessage={activationEmailStatus}
                />
                <UserAccountAction
                    title="Password Reset"
                    subTitle="Send account setup instructions to the user's username"
                    buttonLabel="Send Reset Email"
                    handleClick={sendResetPasswordEmail}
                    isDisabled={usernameSending.isDisabled}
                    uniqueID="btnUserAccountActionPasswordReset"
                    statusMessage={resetPasswordStatus}
                />
            </fieldset>
        </>
    );
};

export default UserAccountActionsindex;
